import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import("@/components/Home/HomePage.vue")
    }, {
        path: '/receive',
        name: 'receive',
        component: () => import("@/components/Receive/ReceiveMessage.vue")
    }, {
        path: '/consultation',
        name: 'consultation',
        component: () => import("@/components/Consultation/ConsultationPanel.vue")
    }, {
        path: "/bind",
        name: 'bind',
        component: () => import("@/components/BindComponent/BindComponent.vue")
    }, {
        path: "/jd",
        name: 'jd',
        component: () => import("@/components/Receive/JDCom.vue")
    }, {
        path: "/mt",
        name: 'mt',
        component: () => import("@/components/Receive/MeitneriumComponent.vue")
    }, {
        path: "/tm",
        name: 'tm',
        component: () => import("@/components/Receive/TmallComponent.vue")
    },{
        path: "/dy",
        name: 'dy',
        component: () => import("@/components/Receive/TikTok.vue")
    }
]

const router = new VueRouter({
    routes
})

export default router
